<template>
  <div class="viewContainer" >
    <v-container
      class="pa-4 pa-sm-8"
    >
      <v-card v-if="loading" class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8 text-center" elevation="0">
        <v-progress-circular
          :size="32"
          color="primary"
          indeterminate
          class="ma-12"
        ></v-progress-circular>
      </v-card>
      <v-card v-if="!loading" class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8" elevation="0">
        <div class="content-container">
          <h2 class="text-center mb-0" v-if="!loading && wallet !== null && Array.isArray(wallet) && wallet.length == 0">
            {{ $t('wallet_head_empty') }}
          </h2>
          <div v-if="wallet !== null && !loading">
            <div
              v-for="(item, index) in wallet"
              :key="index"
              class="position-relative"
            >
              <!-- <v-btn 
                color="btn-convert-wallet" 
                depressed 
                rounded
                class="no-caps"
                :loading="loading_transfer && index==index_transfer" 
                @click="openTransferWallet(item.uuid,index)"
              >
                <v-icon left v-if="text_convert">mdi-account-convert</v-icon>
                {{ $t('transfer_point') }}
              </v-btn> -->
              <v-card 
              :class="(index > 0) ? 'mt-4' : ''"
              outlined
              :to="{ name: 'customer.card', params: { uuid: item.uuid }}">
                <!-- <v-img
                  v-if="item.cover"
                  :src="item.cover"
                /> -->
                <v-card-title>
                  <!-- <v-icon left color="ctaBg">
                    mdi-wallet-giftcard
                  </v-icon> -->
                  <v-avatar
                    color="grey"
                    size="48"
                  >
                    <v-img 
                      v-if="item.cover"
                      :src="item.cover"
                    />
                    <span v-if="!item.cover" class="white--text text-subtitle-1">{{ `${item.name.charAt(0).toUpperCase()}${item.name.charAt(1).toUpperCase()}` }}</span>
                  </v-avatar>
                  <span class="text-h6 ml-4">{{ item.name }}</span>
                </v-card-title>

                <v-card-text class="primary--text">
                  <div class="d-flex align-baseline justify-space-between">
                    <div class="d-flex align-center text-h4 font-weight-medium">
                      <v-icon left size="inherit" class="primary--text">mdi-circle-multiple-outline</v-icon> {{ formatNumber(item.balance.points) }}
                    </div>
                    <div class="text-body-1 grey--text ml-4"><span v-html="formatCurrency(item.balance.value, item.balance.currency)"/></div>
                  </div>
                  <div class="text-subtitle-1 mt-4" v-if="item.desc" v-html="item.desc"/>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="primary--text pa-4 d-flex align-center justify-space-between text-body-1">
                  <div v-if="!item.balance.redeemable">{{ $t('points_required_for_discount', { points: formatNumber(item.balance.pointsRequired) }) }}</div>
                  <div v-if="item.balance.redeemable" class="">{{ $t('enough_for_discount') }}</div>
                  <v-icon small>mdi-arrow-right</v-icon>
                </v-card-actions>
              </v-card>
            </div>
          </div>
        </div>
      </v-card>
    </v-container>
  </div>
</template>
<script>
// import MenuCustomer from '@/components/ui/MenuCustomer.vue'
export default {
  // components: {
  //   MenuCustomer
  // },
  data: () => ({
    locale: 'th',
    tab: 'tab-0',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    dialogGenerateDiscountCode: false,
    selectedCard: null,
    generateDiscountCodeForm: {
      loading: false
    },
    loading: true,
    loading_transfer: false,
    index_transfer: -1,
    wallet: null,
    text_convert: true
  }),
  created () {
    // Set locale
    //console.log(this.$root.$mainMenu)
    this.locale = 'th'
    this.moment.locale(this.locale.substr(0,2))

    this.axios
      .get('/customer/wallet', { params: { locale: this.$i18n.locale }})
      .then(response => {
        this.wallet = response.data.wallet
        //this.wallet = []
        this.loading = false
      })
  },
  methods: {
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    formatCurrency (number, currency) {
      return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: currency}).format(number)
    },
    openTransferWallet(uuid,index){
      this.loading_transfer = true
      this.index_transfer = index
      this.axios
        .post('/customer/get-data-exchange-point', {
          uuid: uuid
        })
        .then(response => {
          if (response.data.status === 'success') {
            //console.log(response.data)
            if(response.data.exchange_points){
              this.$root.$SelectBusinessExchangePoint()
              .then((confirm) => {
                if (confirm.confirm) {
                  //console.log(confirm)
                  if(confirm.type == 'to_customer'){
                    this.$root.$TransferWallet(uuid)
                    .then((confirm_transfer_wallet) => {
                      if (confirm_transfer_wallet.confirm) {
                        this.loading = true
                        this.axios
                        .get('/customer/wallet', { params: { locale: this.$i18n.locale }})
                        .then(response => {
                          this.wallet = response.data.wallet
                          this.loading = false
                        })
                      }
                    })  
                  }
                  else if(confirm.type == 'to_business'){
                    this.$root.$ExchangePoints(response.data.data,uuid)
                    .then((confirm_transfer_point) => {
                      if (confirm_transfer_point.confirm) {
                        //console.log(confirm_transfer_point)
                        this.loading = true
                        this.axios
                        .get('/customer/wallet', { params: { locale: this.$i18n.locale }})
                        .then(response => {
                          this.wallet = response.data.wallet
                          this.loading = false
                        })
                      }
                    })  
                  }
                }
              }) 
            }
            else{
              this.$root.$TransferWallet(uuid)
              .then((confirm) => {
                if (confirm.confirm) {
                  this.loading = true
                  this.axios
                  .get('/customer/wallet', { params: { locale: this.$i18n.locale }})
                  .then(response => {
                    this.wallet = response.data.wallet
                    this.loading = false
                  })
                }
              })  
            }
          }
        })
        .catch(error => {
          this.$root.$snackbar(this.$t('not_found'))
        })
        .finally(() => { 
          this.loading_transfer = false
          this.index_transfer = -1
        })
    },
  }
}
</script>
<style>
.btn-convert-wallet{
  position: absolute;
  right: 0;
  margin: 12px;
  z-index: 1;
}
.position-relative{
  position: relative;
}
</style>